
import React, { useEffect } from 'react';
import "./app.css"
import Layout from './features/layout/Layout';
import CustomNoteTemplate from './features/pages/CustomNoteTemplate';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AllNotes from './features/pages/AllNotes';
import { SignedIn, SignedOut, useAuth, useUser } from '@clerk/clerk-react';
import SignUp from './features/pages/SignUp';
import SignIn from './features/pages/SignIn';
import NoteDetails from './features/pages/NoteDetails';
import Onboarding from './features/pages/OnBoarding';
import SendTokenMobile from './features/pages/SignInMobile';
import SingedInReRouting from './components/SingedInReRouting';
import SignedOutRouting from './components/SignedOutRouting';
import Subscription from './components/subscription/Subscription';
import NewNoteDetailsContent from './components/newNoteDetailsContent/NewNoteDetailsContent';
import { tourSteps } from './utils';
import Success from './features/pages/Success';
import Cancel from './features/pages/Cancel';
import { setSubscriptionData } from './slices/stripeSubscriptionSlice';
import useApiRequest from './hooks/useHandleRequests';
import { useDispatch } from 'react-redux';
import DynamicPromptPage from './features/pages/DynamicPromptPage';
import RequestTemplatePage from './features/pages/RequestTemplatePage';
import PromptCustomization from './features/pages/PromptCustomization';
import ProtectedRoute from './components/ProtectedRoute';
import OrgDetails from './features/pages/OrgDetails';
import SuperAdminDashboard from './features/pages/SuperAdminDashboard';
import SuperAdminOrgDetails from './features/pages/SuperAdminOrgDetails';


function App() {
  const { isSignedIn } = useAuth();
  const { user } = useUser();
  // useEffect(()=>{
  //   window.$crisp = [];
  //   window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
  //   (function () {
  //     const d = document;
  //     const s = d.createElement('script');
  //     s.src = 'https://client.crisp.chat/l.js';
  //     s.async = 1;
  //     d.getElementsByTagName('head')[0].appendChild(s);
  //   })();
  // },[])
  const dispatch = useDispatch();
  const { apiRequest: getStripeStatus } = useApiRequest({
    handleError: (err) => {
      console.log(err);
    },
    handleResponse: (data) => {
      dispatch(
        setSubscriptionData({
          hasActiveSubscription: data.hasActiveSubscription,
          subscriptionDetails: data.subscription,
        })
      );
    },
    showSuccessSnackbar: false,
  });

  const isUserOnboarded = () => {
    return user?.publicMetadata?.stripeId || false; // Use Clerk's publicMetadata to store onboarding status
  };

  useEffect(() => {
    if (isSignedIn) {
      getStripeStatus(`/v2/stripe/status`);
    }
  }, [isSignedIn]);

  return (
    <BrowserRouter>
      <SignedOut>
        <Routes>
            <Route path='/' element={<SignIn />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='*' element={<SignedOutRouting />} />
          </Routes>
        </SignedOut>
        <SignedIn>
          <Routes>
            <Route path='/on-boarding' element={<Onboarding />} />
            {/* <Route
            path="/on-boarding"
            element={
              isUserOnboarded() ? (
                <Navigate to="/all-notes" replace />
              ) : (
                <Onboarding />
              )
            }
          /> */}
            <Route path='/auth-token' element={<SendTokenMobile />} />
            <Route element={<Layout />}>
              <Route path='/test' element={<NewNoteDetailsContent />} />
              <Route path='/all-notes' element={<AllNotes tourSteps={tourSteps} />} />
              <Route path='/all-notes/:id' element={<NoteDetails />} />
              <Route path='/org-details' element={<OrgDetails />} />
              <Route path="/all-organizations" element={<SuperAdminDashboard />} />
              <Route path="/all-organizations/:orgId" element={<SuperAdminOrgDetails />} />
              <Route path='/custom-note-template' element={<CustomNoteTemplate /> } />
              {/* <Route path='/analytics' element={<Analytics/> } /> */}
              <Route path='/subscription' element={<Subscription />} />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
              <Route path="/prompts" element={<DynamicPromptPage />} />
              <Route path="/request-template" element={<RequestTemplatePage />} />
              <Route
                path="/prompt/customization"
                element={
                  <ProtectedRoute>
                    <PromptCustomization />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path='*' element={<SingedInReRouting />} />
        </Routes>
        </SignedIn>
    </BrowserRouter>
  );
}

export default App;

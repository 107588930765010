import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Button,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useApiRequest from "../../hooks/useHandleRequests";

const SuperAdminDashboard = () => {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const { apiRequest } = useApiRequest({
    handleResponse: (data) => {
      setLoading(false);
      setOrganizations(data.organizations);
    },
    handleError: (err) => {
      console.error("Error fetching organizations:", err);
      setError("Failed to load organizations.");
      setLoading(false);
    },
  });

  useEffect(() => {
    apiRequest("/v2/org/organizations", "get");
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" sx={{ mt: 4, mb: 4, fontWeight: "bold", textAlign: "start" }}>
        All Organizations
      </Typography>

      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "24px" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: "#1976d2" }}>
                <TableRow>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>#</TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>Organization Name</TableCell>
                  {/* <TableCell sx={{ color: "white", fontWeight: "bold" }}>Organization ID</TableCell> */}
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {organizations.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No organizations found.
                    </TableCell>
                  </TableRow>
                ) : (
                  organizations
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((org, index) => (
                      <TableRow key={org.id} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" } }}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{org.name.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}</TableCell>
                        {/* <TableCell>{org.id}</TableCell> */}
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(`/all-organizations/${org.id}`)}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={organizations.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Container>
  );
};

export default SuperAdminDashboard;

import React, { useState, useEffect } from "react";
import { Box, Typography, Tabs, Tab, CircularProgress, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import metadata from "../../utils/metadataForPrompts";
import useApiRequest from "../../hooks/useHandleRequests";
import { useAuth, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

const DynamicPromptPage = () => {
  const templateKeys = Object.keys(metadata);
  const [activeTab, setActiveTab] = useState(null);
  const navigate = useNavigate();
  const { userId } = useAuth();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true); 

  const userProfession = user?.publicMetadata?.profession || "";
  const userOrganization = user?.organizationMemberships[0]?.organization?.name || "";
  const allowedTemplates =
    userOrganization === "mtep"
      ? ["MtepEmergencyMedicine", "MtepFastTrack", "MtepTeachingNote"]
      : userProfession === "Emergency Medicine"
      ? ["EmergencyMedicine", "FastTrack"]
      : userProfession === "Hospitalist"
      ? ["Hospitalist", "HospitalistHP", "HospitalistProgress", "HospitalistDischarge"]
      : userProfession === "Primary Care Provider"
      ? ["SOAP", "PCProblemBased"]
      : userProfession === "Psychiatrist"
      ? ["PsychiatryIntake", "PsychiatryDetailed"]
      : ["EmergencyMedicine", "Dentist", "Counselor"];

  const filteredTemplateKeys = templateKeys.filter((key) =>
    allowedTemplates.length > 0 ? allowedTemplates.includes(key) : true
  );

  const selectedTemplate = filteredTemplateKeys[activeTab]; 
  const sectionData = metadata[selectedTemplate]?.noteSection || { exampleNote: [] };

  const { apiRequest } = useApiRequest({
    handleResponse: (data) => {
      setIsLoading(false);
      const matchedTabIndex = filteredTemplateKeys.findIndex(
        (key) => metadata[key].title === data.prompt.prompt_title
      );
      if (matchedTabIndex !== -1) {
        setActiveTab(matchedTabIndex);  
      }else {
        setActiveTab(null); 
      }
    },
    successMessage: "Your template has been successfully updated!",
    showSuccessSnackbar: true,
    handleError: (err) => {
      console.error("Error fetching user prompt:", err);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (userId) {
      apiRequest(`/v2/prompt/${userId}`, "get");
    }
  }, [userId]);

  const handleTabChange = async (event, newValue) => {
    setActiveTab(newValue);
    const newSelectedTemplate = filteredTemplateKeys[newValue];

    if (userId && newSelectedTemplate) {
      setIsLoading(true);
      try {
        await apiRequest(`/v2/prompt/${userId}`, "put", {
          prompt_title: metadata[newSelectedTemplate].title,
        });
        console.log("Template successfully updated.");
      } catch (error) {
        console.error("Error updating template:", error);
      }
    }
  };

  const renderContent = () => (
    userOrganization !== "mtep" ? (
      <Box
        sx={{
          border: "1px solid #D1D5DB",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
          p: 2,
          mt: 3,
        }}
      >
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            {sectionData.exampleNote.map((text, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  whiteSpace: "pre-line",
                  color: "#000000de",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {text}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Box>
    ) : null
  );

  return (
    <Box sx={{ maxWidth: "1200px", margin: "auto", mt: 4, px: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            textAlign: "left",
            fontSize: "32px",
            color: "#1A202C",
            mb: 3,
          }}
        >
          Note Templates
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/request-template")}
          sx={{
            backgroundColor: "#007BFF",
            color: "#fff",
            fontWeight: 600,
            borderRadius: "8px",
            fontSize: "16px",
            maxHeight: "45px",
            textTransform: "none",
            ml: 2,
            px: 3,
            py: 1.5,
            border: "1px solid #007BFF",
            boxShadow: "0px 1.54px 1.54px rgba(0, 0, 0, 0.25)",
            mb: 3,
            "&:hover": { backgroundColor: "#155A9C" },
          }}
        >
          Request Template
        </Button>
      </Box>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{
          mb: 3,
          "& .MuiTabs-indicator": { display: "none" },
          "& .MuiTab-root": {
            textTransform: "none",
            fontWeight: 600,
            fontSize: "16px",
            borderRadius: "5px",
            minHeight: "40px",
            minWidth: "120px",
            color: "#202224",
            backgroundColor: "#F3F4F6",
            marginRight: "8px",
            border: "1px solid #E0E0E0",
            boxShadow: "0px 1.54px 1.54px rgba(0, 0, 0, 0.25)",
            "&:hover": { backgroundColor: "#E3F2FD" },
          },
          "& .Mui-selected": {
            color: "#FFFFFF !important",
            backgroundColor: "#007BFF !important",
            border: "1px solid #007BFF",
            boxShadow: "0px 1.54px 1.54px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        {filteredTemplateKeys.map((key, index) => (
          <Tab key={index} label={metadata[key].title} />
        ))}
      </Tabs>

      {isLoading ? (
        <CircularProgress sx={{ display: "block", margin: "auto", mt: 4 }} />
      ) : (
        renderContent()
      )}
    </Box>
  );
};

export default DynamicPromptPage;

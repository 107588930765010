import React, { useEffect } from 'react';
import { Box, TextField, Button, CircularProgress } from '@mui/material';
import useHandleFormik from '../../hooks/usehandleFormik';
import { customPhraseFormSchema } from '../../utils/schemas';

const CustomPhraseForm = ({selectedPhrase, onSave, isCreating }) => {

const {values, isSubmitting, getFieldProps, handleSubmit, setValues} = useHandleFormik(selectedPhrase, customPhraseFormSchema, handleFormSubmit)

useEffect(()=>{
  setValues(selectedPhrase);
},[selectedPhrase])

function handleFormSubmit()  {
  onSave(values)
}

  return (
    <>
      <Box display="flex" gap={2} marginBottom={2}>
        {/* <TextField
          sx={{ backgroundColor: 'white'}}
          placeholder="Code"
          variant="outlined"
          fullWidth
          {...getFieldProps('code')}
          disabled={isSubmitting}
        /> */}
        <TextField
          sx={{ backgroundColor: 'white'}}
          placeholder="Title"
          variant="outlined"
          fullWidth
          {...getFieldProps('title')}
          disabled={isSubmitting}
        />
      </Box>
      <TextField
        sx={{ backgroundColor: 'white'}}
        placeholder="Enter here what you want to see whenever you use this custom phrase"
        variant="outlined"
        multiline
        rows={5}
        fullWidth
        {...getFieldProps('content')}
        marginBottom={2}
        disabled={isSubmitting}
      />
      <Button sx={{
        display: 'block',
        margin: '15px auto'
      }} variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting} startIcon={isSubmitting && <CircularProgress />}>
        {
          isCreating ? 'Save' : 'Update'
        }
      </Button>
    </>
  );
};

export default CustomPhraseForm;

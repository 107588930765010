import { useState } from "react";

export function useCopyMedicalData() {
  const [copied, setCopied] = useState(false);

  const copyHandler = (data) => {
    if (!data || typeof data !== 'string' || data.trim() === '') {
      console.warn('No valid data to copy');
      return;
    }

    if (navigator.clipboard) {
      navigator.clipboard.writeText(data).then(
        () => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        },
        (err) => {
          console.error('Failed to copy to clipboard:', err);
        }
      );
    } else {
      // Fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = data;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy using fallback:', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return { copyHandler, copied };
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { canadianCTHeadRule, canadianCTHeadScreening } from '../../utils';
import { closeTool } from '../../slices/clinicalToolSlice';
import { Card, CardContent, Box, IconButton, Stack, Typography, Grid2 as Grid, List, ListItemButton, ListItemText, Button } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { updateNoteDetails } from '../../slices/noteDetialsSlice';

const CTHead = ({ evaluateDataOnPoints = () => {} }) => {
  const dispatch = useDispatch();
  const [preReq, setPreReq] = useState(() => {
    const savedPreReq = localStorage.getItem('ctHeadPreReq');
    return savedPreReq ? JSON.parse(savedPreReq) : canadianCTHeadScreening.map(() => -1);
  });

  const [selectionSections, setSelectedSection] = useState(() => {
    const savedSelection = localStorage.getItem('ctHeadSelectionSections');
    return savedSelection ? JSON.parse(savedSelection) : {};
  });

  // Save data to localStorage whenever preReq or selectionSections change
  useEffect(() => {
    localStorage.setItem('ctHeadPreReq', JSON.stringify(preReq));
  }, [preReq]);

  useEffect(() => {
    localStorage.setItem('ctHeadSelectionSections', JSON.stringify(selectionSections));
  }, [selectionSections]);

  useEffect(() => {
    setSelectedSection({});
  }, [preReq]);

  const points = Object.values(selectionSections).reduce((acc, curr) => acc + curr.value, 0);

  const getContent = () => {
    return {
      heading: `${canadianCTHeadRule.name.toUpperCase()} SCORE:`,
      key: canadianCTHeadRule.name,
      content: `Result Summary\n${points} points ${evaluateDataOnPoints && evaluateDataOnPoints(points)}\nInputs:\n${Object.entries(selectionSections).map(([key, value]) => `${key} -> ${value.value} = ${value.label}`).join('\n')}`,
    };
  };

  return (
    <Card variant="elevation" elevation={3} sx={{ width: '100%', marginBottom: '15px', backgroundColor: '#EDF2FF' }}>
      <CardContent>
        <Stack direction="row-reverse">
          <IconButton onClick={() => {
            localStorage.removeItem('selectedTool')
            localStorage.removeItem('ctHeadPreReq'); 
            dispatch(closeTool())
            }} size="large" color="primary">
            <Close />
          </IconButton>
        </Stack>
        <Typography color="#202224" fontWeight={500} fontSize="24px">
          {canadianCTHeadRule.name}
        </Typography>
        {canadianCTHeadScreening &&
          canadianCTHeadScreening.length > 0 &&
          canadianCTHeadScreening.map((item, index) => (
            <Grid container component="main" spacing={3} key={item.name}>
              <Grid sx={{ margin: '15px 0px' }} size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <Typography color="#202224" fontWeight={500} fontSize="18px">
                  {item.name}
                </Typography>
                {item.description && (
                  <Typography color="#5E5E5E" fontWeight={500} fontSize="16px">
                    {item.description}
                  </Typography>
                )}
              </Grid>
              <Grid size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <List sx={{ bgcolor: 'white', margin: '10px 0px', padding: '0px', borderRadius: '8px' }}>
                  {item.options.map((option) => {
                    const selected = option.value === preReq[index];
                    if (preReq[index] === -1 || selected)
                      return (
                        <ListItemButton
                          selected={selected}
                          onClick={() => {
                            setPreReq((pre) => {
                              const new_pre = [...pre];
                              new_pre[index] = selected ? -1 : option.value;
                              return new_pre;
                            });
                          }}
                          dense
                          sx={{
                            borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
                            '&.Mui-selected': {
                              backgroundColor: '#2E6FF3',
                              color: 'white',
                            },
                            '&.Mui-selected:hover': {
                              backgroundColor: '#2E6FF3',
                              color: 'white',
                            },
                          }}
                          key={option.id}
                        >
                          <ListItemText
                            primary={option.label}
                            primaryTypographyProps={{
                              fontSize: '14px',
                              fontWeight: 500,
                              maxWidth: '100%',
                              textAlign: 'center',
                            }}
                          />
                        </ListItemButton>
                      );
                    return null;
                  })}
                </List>
              </Grid>
            </Grid>
          ))}
        {!preReq.includes(-1) &&
          !preReq.includes(1) &&
          canadianCTHeadRule.sections.map((section) => (
            <Grid container component="main" spacing={3} key={section.name}>
              <Grid sx={{ margin: '15px 0px' }} size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <Typography color="#202224" fontWeight={500} fontSize="18px">
                  {section.name}
                </Typography>
                {section.description && (
                  <Typography color="#5E5E5E" fontWeight={500} fontSize="16px">
                    {section.description}
                  </Typography>
                )}
              </Grid>
              <Grid size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <List sx={{ bgcolor: 'white', margin: '10px 0px', padding: '0px', borderRadius: '8px' }}>
                  {section.options.map((sectionItem) => {
                    const selected = selectionSections[section.name]?.id === sectionItem.id;
                    if (!selectionSections[section.name] || selected) {
                      return (
                        <ListItemButton
                          selected={selected}
                          onClick={() => {
                            setSelectedSection((prevSections) => {
                              const newSections = { ...prevSections };
                              if (selected) {
                                delete newSections[section.name];
                              } else {
                                newSections[section.name] = sectionItem;
                              }
                              return newSections;
                            });
                          }}
                          dense
                          sx={{
                            borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
                            '&.Mui-selected': {
                              backgroundColor: '#2E6FF3',
                              color: 'white',
                            },
                            '&.Mui-selected:hover': {
                              backgroundColor: '#2E6FF3',
                              color: 'white',
                            },
                          }}
                          key={sectionItem.id}
                        >
                          <ListItemText
                            primary={sectionItem.label}
                            primaryTypographyProps={{
                              fontSize: '14px',
                              fontWeight: 500,
                              maxWidth: '80%',
                            }}
                          />
                          <Typography color={selected ? 'white' : 'rgba(0, 0, 0, 0.38)'} fontSize="10px" fontWeight={600}>
                            {sectionItem.value > 0 ? '+' : ''}
                            {sectionItem.value}
                          </Typography>
                        </ListItemButton>
                      );
                    }
                    return null;
                  })}
                </List>
              </Grid>
            </Grid>
          ))}
      </CardContent>
      <Box bgcolor="white" padding="15px" marginBottom="30px">
        <Grid component="main" container spacing={5}>
          <Grid size={{ lg: 8, md: 8, sm: 12, xs: 12 }}>
            {!preReq.includes(-1) && !preReq.includes(1) && (
              <>
                <Typography fontSize="28px" fontWeight={600}>
                  {points} points
                </Typography>
                <Typography>Inputs:</Typography>
                {Object.entries(selectionSections).map(([key, value]) => (
                  <Typography key={key}>{`${key} -> ${value.value} = ${value.label}`}</Typography>
                ))}
              </>
            )}
            {preReq.includes(1) && <Typography color="error">Not Eligible</Typography>}
          </Grid>
          <Grid size={{ lg: 4, md: 4, sm: 12, xs: 12 }}>
            {evaluateDataOnPoints && evaluateDataOnPoints(points)}
          </Grid>
        </Grid>
        <Stack marginTop="15px" direction="row-reverse">
          <Button
            disabled={
              Object.keys(selectionSections).length !== canadianCTHeadRule.sections.length || preReq.includes(-1) || preReq.includes(1)
            }
            onClick={() => {
              localStorage.setItem('Dtool', true)
              dispatch(updateNoteDetails({ key: 'summary_json', value: getContent() }));
              localStorage.removeItem('selectedTool')
              localStorage.removeItem('ctHeadPreReq'); 
              dispatch(closeTool());
            }}
            sx={{ bgcolor: '#2E6FF3' }}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Add />}
          >
            Insert
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};

export default CTHead;

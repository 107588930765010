import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Alert,
  Button,
  Box,
} from "@mui/material";
import useApiRequest from "../../hooks/useHandleRequests";
import { saveAs } from "file-saver";

const OrgDetails = () => {
  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { apiRequest } = useApiRequest({
    handleResponse: (data) => {
      setLoading(false);
      setOrgData(data);
    },
    handleError: (err) => {
      console.error("Error fetching organization data:", err);
      setError("Failed to load organization data.");
      setLoading(false);
    },
  });

  useEffect(() => {
    apiRequest("/v2/org/org-users", "get");
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadCSV = () => {
    if (!orgData || !orgData.users || orgData.users.length === 0) {
      console.warn("No data available for download.");
      return;
    }

    const csvHeaders = "Name, Email, Prompt Template, Notes Count\n";
    const csvRows = orgData.users.map(
      (user) =>
        `${user.name}, ${user.email}, ${user.promptTemplate || "No Template"}, ${user.noteCount}`
    );

    const csvContent = csvHeaders + csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const fileName = `OrgUsers_${orgData.organizationName}_${new Date().toISOString().slice(0, 19)}.csv`;
    saveAs(blob, fileName);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mt: 4, mb: 2, fontWeight: "bold", textAlign: "start" }}>
        Organization Members
      </Typography>

      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button
              variant="contained"
              color="success"
              sx={{
                fontWeight: "bold",
                boxShadow: 3,
                borderRadius: 2,
                transition: "0.3s",
                "&:hover": { transform: "scale(1.05)", backgroundColor: "#2e7d32" },
              }}
              onClick={handleDownloadCSV}
            >
              Download CSV
            </Button>
          </Box>
          <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: "#1976d2" }}>
                <TableRow>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>#</TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>User Name</TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>Email</TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>Prompt Template</TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>Note Count</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orgData.users.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No users found in this organization.
                    </TableCell>
                  </TableRow>
                ) : (
                  orgData.users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user, index) => (
                      <TableRow key={user.id} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f5f5f5" } }}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.promptTemplate || "No Template Assigned"}</TableCell>
                        <TableCell>{user.noteCount}</TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={orgData.users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Container>
  );
};

export default OrgDetails;

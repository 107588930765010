import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Button,
  Box,
  Pagination,
} from "@mui/material";
import { saveAs } from "file-saver";
import useApiRequest from "../../hooks/useHandleRequests";

const SuperAdminOrgDetails = () => {
  const { orgId } = useParams();
  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const { apiRequest } = useApiRequest({
    handleResponse: (data) => {
      setLoading(false);
      setOrgData(data);
    },
    handleError: (err) => {
      console.error("Error fetching organization data:", err);
      setError("Failed to load organization data.");
      setLoading(false);
    },
  });

  useEffect(() => {
    apiRequest(`/v2/org/organization/${orgId}`, "get");
  }, [orgId]);

  const handleDownloadCSV = () => {
    if (!orgData || !orgData.users || orgData.users.length === 0) return;
    const csvHeaders = "User ID, Name, Email, Prompt Template, Notes Count\n";
    const csvRows = orgData.users.map(
      (user) =>
        `${user.id}, ${user.name}, ${user.email}, ${user.promptTemplate || "No Template"}, ${user.noteCount}`
    );
    const csvContent = csvHeaders + csvRows.join("\n");
    saveAs(
      new Blob([csvContent], { type: "text/csv;charset=utf-8;" }),
      `OrgUsers_${orgData.organizationName}.csv`
    );
  };

  // Pagination logic
  const totalPages = Math.ceil((orgData?.users?.length || 0) / itemsPerPage);
  const paginatedUsers = orgData?.users?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
        {loading ? "Loading..." : `${orgData?.organizationName?.toUpperCase()} Organization`}
      </Typography>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button
              variant="contained"
              color="success"
              sx={{
                fontWeight: "bold",
                boxShadow: 3,
                borderRadius: 2,
                transition: "0.3s",
                "&:hover": { transform: "scale(1.05)", backgroundColor: "#2e7d32" },
              }}
              onClick={handleDownloadCSV}
            >
              Download CSV
            </Button>
          </Box>

          {/* Users Table */}
          <TableContainer component={Paper} sx={{ boxShadow: 4, borderRadius: 1 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#1976d2" }}>
                <TableRow>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>#</TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>User Name</TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>Email</TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>Prompt Template</TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>Notes Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No users found in this organization.
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedUsers.map((user, index) => (
                    <TableRow key={user.id}>
                      <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.promptTemplate || "No Template Assigned"}</TableCell>
                      <TableCell>{user.noteCount}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          {totalPages > 1 && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_, value) => setCurrentPage(value)}
                color="primary"
                shape="rounded"
              />
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default SuperAdminOrgDetails;

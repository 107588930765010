import { Add, Close } from '@mui/icons-material'
import { Box, Button, Card, CardActionArea, CardContent, IconButton, List, ListItemButton, ListItemText, Stack, Typography, Grid2 as Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { IOSSwitch } from '../styled'
import { MIPS } from '../../utils/other'
import { useDispatch, useSelector } from 'react-redux'
import { updateNoteDetails } from '../../slices/noteDetialsSlice'
import { closeTool } from '../../slices/clinicalToolSlice'

const data = MIPS.mips;

const Mips = () => {
  const dispatch = useDispatch();
  const noteDetails = useSelector(state => state.noteDetails.noteDetails);
  
  const [mips, setMips] = useState(() => {
    // Get the saved mips object from localStorage if available, else use default values
    const savedMips = JSON.parse(localStorage.getItem('mipsState'));
    return savedMips || {
      id: null,
      name: null,
      decesion: 'YES',
      sub_statements_list: []
    };
  });

  useEffect(() => {
    localStorage.setItem('mipsState', JSON.stringify(mips));
  }, [mips]);

  const getContent = (obj) => {
    return obj.sub_statements_list.reduce((acc, curr) => {
        return acc + (curr.selected ? `${curr.selected} ` : '');
    }, mips.name);
};
const isSubStatementPresent = (obj) => {
  if(obj.sub_statements_list.length === 0)
    return false;
    const lastSubStatement = obj.sub_statements_list[obj.sub_statements_list.length - 1];
    return Object.keys(lastSubStatement.sub_statements).length === 0;
};


const getInsertingContent = () =>{
  return ({
    heading: 'MIPS SCORE:',
    content: getContent(mips),
    key: 'MIPS'
  })
  return `${noteDetails.summary}\n\nMIPS: \n${ getContent(mips)}`
}



  return (
    <Card variant='elevation' elevation={3} sx={{width: '100%', marginBottom: '15px', backgroundColor: '#EDF2FF'}}>
      <CardContent>
        <Stack direction="row-reverse">
          <IconButton onClick={()=>{
            dispatch(closeTool())
            localStorage.removeItem('selectedTool')
            localStorage.removeItem('mipsState');
            }} size='large' color='primary'>
            <Close />
          </IconButton>
        </Stack>
        <Typography color='#202224' fontWeight={500} fontSize="20px">
          MIPS Notes
        </Typography>
        <Grid container component="div" spacing={2} margin="15px 0px">
          {data.map(item => {
            const selected = item.id === mips.id;
            return (
              <Grid size={{ lg: 4, md: 4, sm: 4, xs: 4}} key={item.name}>
                <CardActionArea onClick={() => {
                  setMips(mips => ({
                    id: item.id,
                    name: item.name,
                    decesion: 'YES',
                    sub_statements_list: [{
                      sub_statements: item.statements.YES.sub_statements,
                      selected: null
                    }]
                  }))
                }}>
                  <Typography textAlign="center" fontWeight={400} fontSize="14px" padding="15px" bgcolor={selected ? "#2E6FF3" : 'white'} color={selected ? 'white' : 'black'} borderRadius="8px">
                    {item.name}
                  </Typography>
                </CardActionArea>
              </Grid>
            );
          })}
        </Grid>
        {mips.sub_statements_list.length > 0 && (
          <Stack borderRadius="8px" bgcolor="#2E6FF3" alignItems="center" direction="row-reverse" padding="5px 15px" marginBottom="15px">
            <IOSSwitch
              checked={mips.decesion === 'YES'}
              onChange={() => {
                const newDecesion = mips.decesion === 'YES' ? 'NO' : 'YES' 
                setMips(mips => ({
                  ...mips,
                  decesion: newDecesion,
                  sub_statements_list: [{
                    sub_statements: data.find(item => item.id === mips.id).statements[newDecesion].sub_statements,
                    selected: null
                  }]
                }))
              }}
            />
            <Typography flexGrow={1} textAlign="center" fontWeight={400} fontSize="14px" color='white'>
              {mips.name}
            </Typography>
          </Stack>
        )}
        {
          mips.sub_statements_list.length > 0 && 
          mips.sub_statements_list.map((list, index) => {
            return (
              <List sx={{ bgcolor: 'white', margin: '10px 0px', padding: '0px', borderRadius: '8px'}}>
          {
            Object.values(list.sub_statements).map(statement => {
              const selected = statement.name === mips.sub_statements_list[index].selected
            if(!mips.sub_statements_list[index].selected || selected){
              return (
                <ListItemButton
                selected={selected}
                onClick={() => {
                  if(!selected || true){
                    setMips((mips) => {
                      const newSubStatement = {
                        sub_statements: list.sub_statements,
                        selected: selected ? null : statement.name
                      }
                      const new_subStatementList = [...mips.sub_statements_list];
                      new_subStatementList[index] = newSubStatement;
                      new_subStatementList[index + 1] = {
                        sub_statements: statement.sub_statements,
                        selected: null
                      }
                      return ({
                        ...mips,
                        sub_statements_list: new_subStatementList.slice(0, selected ? index + 1 : index + 2)
                      })
                    })
                  }
                }}
                dense
                sx={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
                  '&.Mui-selected': {
                    backgroundColor: '#2E6FF3',
                    color: 'white'
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: '#2E6FF3',
                    color: 'white'
                  }
                }}
          >
            <ListItemText 
              primary={statement.name} 
              primaryTypographyProps={{
                fontSize: '10px',
                fontWeight: 500
              }}
            />
          </ListItemButton>
              )}
              return null
            })
          }
          </List>
            )
          })
        }
        <Box padding="15px" bgcolor="white" margin="10px 0px">
          <Typography color='#202224' fontSize="14px" fontWeight={500} maxWidth="80%">
            {getContent(mips)}
          </Typography>
          <Stack direction="row-reverse">
            <Button disabled={
              !isSubStatementPresent(mips)
            } onClick={()=> {
              localStorage.setItem('Dtool', true);
              dispatch(updateNoteDetails({key: 'summary_json', value: getInsertingContent()}));
              localStorage.removeItem('selectedTool')
              localStorage.removeItem('mipsState');
              dispatch(closeTool())
            }
              } sx={{ bgcolor: '#2E6FF3'}} variant='contained' color='primary' size='large' startIcon={<Add />}>
              Insert
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Mips;

import { Navigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

const allowedEmails = [
    "kamran@drh.ai",
    "ghulam.hur@megasight.ai",
    "hur@drh.ai",
    "moazmumtaz998@gmail.com"
].map(email => email.toLowerCase());

const ProtectedRoute = ({ children }) => {
    const { user } = useUser();
  
    const userEmail = user?.primaryEmailAddress?.emailAddress?.toLowerCase();
  
    if (!user || !allowedEmails.includes(userEmail) || !user.publicMetadata?.isSuperAdmin) {
      return <Navigate to="/all-notes" replace />;
    }
  
    return children;
};
  
  export default ProtectedRoute;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeTool } from "../../slices/clinicalToolSlice";
import { updateNoteDetails } from "../../slices/noteDetialsSlice";
import { Card, CardContent, Typography, Box, List, ListItemButton, ListItemText, TextField, Button, IconButton, Stack } from "@mui/material";
import { Close, Add } from "@mui/icons-material";

const EDACS_Criteria = [
  { id: "diaphoresis", name: "Diaphoresis (Sweating)?", points: +3 },
  { id: "pain_radiation", name: "Pain radiates to arm, shoulder, neck, or jaw?", points: +5 },
  { id: "pain_inspiration", name: "Pain occurred or worsened with inspiration?", points: -4 },
  { id: "pain_palpation", name: "Pain is reproduced by palpation?", points: -6 },
];

const getAgePoints = (age) => {
  if (age < 18) return 0;
  if (age <= 45) return 2;
  if (age <= 50) return 4;
  if (age <= 55) return 6;
  if (age <= 60) return 8;
  if (age <= 65) return 10;
  if (age <= 70) return 12;
  if (age <= 75) return 14;
  if (age <= 80) return 16;
  if (age <= 85) return 18;
  return 20;
};

const EDACSAlgorithm = () => {
  const dispatch = useDispatch();
  const [age, setAge] = useState(localStorage.getItem("edacs_age") || "");
  const [sex, setSex] = useState(localStorage.getItem("edacs_sex") || "");
  const [criteria, setCriteria] = useState(() => JSON.parse(localStorage.getItem("edacs_criteria")) || {});
  const [cadRisk, setCadRisk] = useState(() => {
    const storedValue = localStorage.getItem("edacs_cadRisk");
    return storedValue !== null ? JSON.parse(storedValue) : null;
  });
  const [ecg, setECG] = useState(localStorage.getItem("edacs_ecg") || "");
  const [troponin, setTroponin] = useState(localStorage.getItem("edacs_troponin") || "");

  useEffect(() => {
    localStorage.setItem("edacs_age", age);
    localStorage.setItem("edacs_sex", sex);
    localStorage.setItem("edacs_criteria", JSON.stringify(criteria));
    localStorage.setItem("edacs_cadRisk", cadRisk);
    localStorage.setItem("edacs_ecg", ecg);
    localStorage.setItem("edacs_troponin", troponin);
  }, [age, sex, criteria, cadRisk, ecg, troponin]);

  useEffect(() => {
    if (cadRisk !== null) {
      localStorage.setItem("edacs_cadRisk", JSON.stringify(cadRisk));
    }
  }, [cadRisk]);

  const calculateEDACS = () => {
    let score = getAgePoints(parseInt(age)) + (sex === "male" ? 6 : 0);
    EDACS_Criteria.forEach(({ id, points }) => {
      if (criteria[id] === "yes") score += points;
    });
    if (cadRisk && age <= 50) score += 4;
    return score;
  };

  const getRiskCategory = () => {
    return calculateEDACS() < 16 ? "Low Risk - Safe for Discharge" : "Not Low Risk - Further Evaluation Required";
  };

  const isInsertDisabled = () => {
    return (
      !age ||
      !sex ||
      Object.keys(criteria).length !== EDACS_Criteria.length ||
      Object.values(criteria).includes("")
    );
  };

  const getContent = () => ({
    heading: "EDACS Algorithm Result:",
    key: "edacs_algorithm",
    content: `Patient Information:\n- Age: ${age}\n- Sex: ${sex}\n\nAssessment Results:\n- EDACS Score: ${calculateEDACS()} points\n- Risk Category: ${getRiskCategory()}\n\nClinical Decision:\n${getRiskCategory() === "Low Risk - Safe for Discharge if ECG and Troponins are Normal" ? "Patient may be safely discharged with appropriate follow-up." : "Further evaluation and monitoring are recommended."}`,
  });

  return (
    <Card variant="elevation" elevation={3} sx={{ width: "100%", marginBottom: "15px", backgroundColor: "#EDF2FF" }}>
      <CardContent>
        <Stack direction="row-reverse">
          <IconButton onClick={() => {
            localStorage.removeItem("edacs_age")
            localStorage.removeItem("edacs_sex");
            localStorage.removeItem("edacs_criteria");
            localStorage.removeItem("edacs_cadRisk");
            localStorage.removeItem("edacs_ecg");
            localStorage.removeItem("edacs_troponin");
            dispatch(closeTool());
          }} size="large" color="primary"><Close /></IconButton>
        </Stack>
        <Typography fontWeight={500} fontSize="24px" color="#202224">EDACS Algorithm for Chest Pain Assessment:</Typography>

        <Box bgcolor="white" padding="10px" borderRadius="8px" marginBottom="20px">
          <Typography fontWeight={500} fontSize="18px">Enter Patient Age</Typography>
          <TextField fullWidth label="Age" type="number" value={age} onChange={(e) => setAge(e.target.value)} />
        </Box>

        <List sx={{ bgcolor: "white", borderRadius: "8px", padding: "10px", marginBottom: "10px" }}>
          <Typography fontWeight={500} fontSize="18px">Select Patient</Typography>
          <ListItemButton
            selected={sex === "male"}
            onClick={() => setSex("male")}
            sx={{
              "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
              "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
            }}>
            <ListItemText primary="Male (+6)" /></ListItemButton>
          <ListItemButton selected={sex === "female"}
            sx={{
              "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
              "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
            }} onClick={() => setSex("female")}><ListItemText primary="Female (0)" /></ListItemButton>
        </List>

      {age !== "" && !isNaN(age) && parseInt(age) <= 50 && (
        <Box bgcolor="white" padding="10px" borderRadius="8px" marginBottom="10px">
          <Typography fontWeight={500}>Known coronary artery disease or ≥3 risk factors</Typography>
          <List sx={{ display: "flex", gap: "10px", bgcolor: "white", borderRadius: "8px" }}>
            <ListItemButton
              selected={cadRisk === true}
              onClick={() => setCadRisk(true)}
              sx={{
                flex: 1,
                "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
                "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
              }}
            >
              <ListItemText primary="Yes (+4)" />
            </ListItemButton>
            <ListItemButton
              selected={cadRisk === false}
              onClick={() => setCadRisk(false)}
              sx={{
                flex: 1,
                "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
                "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
              }}
            >
              <ListItemText primary="No" />
            </ListItemButton>
          </List>
        </Box>
      )}
        <List>
          {EDACS_Criteria.map(({ id, name, points }) => (
            <Box key={id} bgcolor="white" padding="10px" borderRadius="8px" marginBottom="10px">
              <Typography fontWeight={500}>{name}</Typography>
              <List sx={{ bgcolor: "white", borderRadius: "8px" }}>
                <ListItemButton
                  sx={{
                    "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
                    "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
                  }}
                  selected={criteria[id] === "yes"} onClick={() => setCriteria({ ...criteria, [id]: "yes" })}>
                  <ListItemText primary={`Yes (${points})`} />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
                    "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
                  }} selected={criteria[id] === "no"} onClick={() => setCriteria({ ...criteria, [id]: "no" })}>
                  <ListItemText primary="No (0)" />
                </ListItemButton>
              </List>
            </Box>
          ))}
        </List>

        <Typography fontSize="20px" fontWeight="600" color="primary">EDACS Score: {calculateEDACS()} points</Typography>
        <Typography fontSize="20px" fontWeight="600" color="primary">Risk Category: {getRiskCategory()}</Typography>

        <Button variant="contained" sx={{ bgcolor: "#2E6FF3", borderRadius: "8px", marginTop: "15px" }} startIcon={<Add />}
          disabled={isInsertDisabled()}
          onClick={() => {
            localStorage.setItem('Dtool', true)
            dispatch(
              updateNoteDetails({ key: 'summary_json', value: getContent() })
            );
            localStorage.removeItem("edacs_age")
            localStorage.removeItem("edacs_sex");
            localStorage.removeItem("edacs_criteria");
            localStorage.removeItem("edacs_cadRisk");
            localStorage.removeItem("edacs_ecg");
            localStorage.removeItem("edacs_troponin");
            dispatch(closeTool());
          }}
        >Insert</Button>
      </CardContent>
    </Card>
  );
};

export default EDACSAlgorithm;

import React, { useEffect, useRef, useState } from 'react'
import { Archive, ArrowBack, Mic, Save, Unarchive } from '@mui/icons-material'
import { Box, Button, Chip, CircularProgress, DialogContent, DialogTitle, Grid, IconButton, Stack, styled, Typography } from '@mui/material'
import { getFormatedDateAndTime, getMobileFormatedDateAndTime, getSelectedNoteTypeParams, noteOptions, noteTypes, notevalues } from '../utils'
import { useDispatch, useSelector } from 'react-redux'
import { setMobileNotes, setNotes } from '../slices/notesSlice'
import useApiRequest from '../hooks/useHandleRequests'
import VoiceRecorder from '../components/AudioRecorder'
import SplashScreen from './splash/SplashScreen'



const StyledChip = styled(Chip)(({theme})=>({
  '&': {
    fontSize: '18px',
    padding: '10px 15px'
  },
}))

const delay = 750;

const MyNotesContent = ({ setInitialState ,draftNotes , setIsCreating, resetRecording}) => {

  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const noteActionRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [selectedNoteType, setSelectedNoteType] = React.useState(noteTypes[0]);
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [noteAction, setnoteAction] = React.useState(null);
  const [creationLoading, setCreationLoading] = useState(false);
  const selectedNoteTypeRef = React.useRef(noteTypes[0]);
  const {mobileNotes} = useSelector(state => state.notes);

  function handleMobileNotes(data){
    if(selectedNoteTypeRef.current === noteTypes[0]){
      dispatch(setMobileNotes([...data.body, ...draftNotes]))
    }
    else{
      dispatch(setMobileNotes(data.body))
    }
  }

  const {apiRequest: getNotes, loading: isLoading} = useApiRequest({

    handleError: ()=> {},
    handleResponse: (data)=> handleMobileNotes(data),
    showSuccessSnackbar: false
  })

  const { apiRequest: archiveNote, loading: archiving, resetResponse } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: async ()=> {
      resetResponse();
      await getNotes(`/v2/note${getSelectedNoteTypeParams[selectedNoteTypeRef.current]}`);
    },
    successMessage: `Note has been updated`
  })

  const { apiRequest: updateStatus} = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: ()=>{},
    showSuccessSnackbar: false
  })

  const { apiRequest: createNote, loading: isCreating } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: async (data) => {
      const fileName = `recording_${new Date().toISOString()}.mp3`;
      const mp3File = new File([audioRef.current], fileName, { type: "audio/mp3" });
      const url = new URL(data.PreSigned)
      const headers = new Headers();
      const contentType = url.searchParams.get("Content-Type");
      if (contentType) {
        headers.set("Content-Type", contentType);
      }
      try{
        // await updateStatus(`/v2/note/${data.Note.id}/status`, 'put', { status: noteActionRef.current.startStatus })
        const response = await fetch(url, {
          method: "PUT",
          body: mp3File,
          headers: headers,
        });
        // await updateStatus(`/v2/note/${data.Note.id}/status`, 'put', { status: noteActionRef.current.endStatus })
        setAudioUrl(null);
        audioRef.current = null;
        setShowSplashScreen(true);
       }catch(err){
        console.log(err)
       }
       finally{
        setCreationLoading(false)
       }
    },
    successMessage: "Note has been created"
  })

  useEffect(()=>{
    selectedNoteTypeRef.current = selectedNoteType;
    if(selectedNoteType === 'Drafts'){
      dispatch(setMobileNotes(draftNotes));
    }
    else{
    getNotes(`/v2/note${getSelectedNoteTypeParams[selectedNoteType]}`)
    }
  },[selectedNoteType])

  useEffect(()=>{
    noteActionRef.current = noteAction;
  },[noteAction])

  useEffect(()=>{
    let handler; 
    if(showSplashScreen){
      handler = setTimeout(()=>{
        setnoteAction(null);
        setShowSplashScreen(false);
      },delay)
    }
    return () => {
      if(handler){
        clearTimeout(handler)
      }
    }
  },[showSplashScreen])

  if(noteAction){
    return (
      <>
      <Grid container sx={{padding: '10px'}}>
        <Grid item xs={4} sm={4} md={4} lg={4}>
        <IconButton onClick={()=>setnoteAction(null)} size="large" color='primary'>
          <ArrowBack />
        </IconButton>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
        <Typography textAlign="center" color='#2E6FF3' fontWeight={700} fontSize="32px">
          {noteAction.label}
        </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
        </Grid>
      </Grid>
      {
        showSplashScreen ? <SplashScreen /> : (
          <DialogContent sx={{
            height: '600px'
          }}>
              <Stack gap={2} marginBottom="45px">
                <Typography fontWeight={500} fontSize="36px" fontFamily="sans-serif" color='#33384B' textAlign="center">
                  {noteAction?.note?.patient?.innitials}
                </Typography>
                <Typography fontWeight={500} fontSize="28px" fontFamily="sans-serif" color='#33384B' textAlign="center">
                  {noteAction?.note?.patient?.sex} | {noteAction?.note?.patient?.age} {noteAction?.note?.patient?.ageType}
                </Typography>
                <Typography fontWeight={500} fontSize="28px" fontFamily="sans-serif" color='#33384B' textAlign="center">
                  {noteAction?.note?.locationType} {noteAction?.note?.location} 
                </Typography>
              </Stack>
            { creationLoading ? 
            <Stack gap={3} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
             : <VoiceRecorder resetRecording={resetRecording} saveRecording={(blob) => {
              audioRef.current = blob
              if(blob !== null){
                const audioURL = URL.createObjectURL(blob);
                setAudioUrl(audioURL);
                createNote('/v2/note','post', {
                  ...noteAction.note.patient,
                  parentNote: noteAction.note.id,
                  locationType: noteAction.note.locationType,
                  noteType: noteAction.noteType,
                  status: noteAction.startStatus,
                  device: 'web'
                  })
                  setCreationLoading(true)
              }
              else {
                setAudioUrl(blob)
              }
            }} />}
            {/* {audioUrl && <audio controls controlsList="nodownload noplaybackrate" style={{ width: '100%'}} src={audioUrl} />} */}
            {/* <Stack component="div" justifyContent="center" alignItems="center">
              <Button disabled={!audioRef.current || creationLoading} startIcon={<Save />} variant='contained' onClick={()=> {
                createNote('/v2/note','post', {
                ...noteAction.note.patient,
                parentNote: noteAction.note.id,
                locationType: noteAction.note.locationType,
                noteType: noteAction.noteType,
                status: noteAction.startStatus
                })
                setCreationLoading(true)
                }}>
                Create
              </Button>
            </Stack> */}
            </DialogContent>
        )
      }
      </>
    )
  }
  return (
    <>
      <DialogTitle textAlign="center" color='#2E6FF3' fontWeight={700} fontSize="32px" margin="15px 0px">
        My Notes
        <Button size='large' onClick={()=> setIsCreating(true)} sx={{margin: '15px 0px', borderRadius: '32px'}} fullWidth variant='contained' startIcon={<Mic />} color='error'>
        Create Note
      </Button>
      <Stack gap={4} justifyContent="space-between" margin="15px 0px" direction="row">
      {
        noteTypes.map(item => (
          <StyledChip disabled={isLoading} label={item} variant='outlined' color={selectedNoteType === item ? 'primary' : 'default' } onClick={()=>setSelectedNoteType(item)} />
        ))
      }
      </Stack>
      </DialogTitle>
      <DialogContent sx={{
      height: '900px'
    }}>
      <Stack gap={1} minHeight="350px">
      {
        isLoading ? <CircularProgress sx={{ margin: '15px auto'}} /> : mobileNotes.map(note => {
          const isDraftNote = note.noteType === 'Drafts'; 
          return (
            <Box bgcolor={isDraftNote ? "rgba(255, 248, 188, 1)" : "rgba(0, 122, 255, 0.05)"} padding="15px" borderRadius="18px">
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box component="div" display="flex" gap={1} alignItems="center">
                  <Typography component="span" color='black' fontSize="24px" fontWeight={500}>
                    {note.patient.innitials}
                  </Typography>
                  <Typography component="span" color='rgba(27, 31, 38, 0.72)' fontSize="18px" fontWeight={400}>
                     {note.patient.age} {note.patient.ageType} | {note.patient.sex[0]} {isDraftNote && '(Draft)'}
                  </Typography>
                </Box>
                <IconButton disabled={archiving || isDraftNote} onClick={()=> archiveNote(`/v2/note/${note.id}`,'put',{ isArchived: !note.isArchived})} size='large' color='primary'>
                  {note.isArchived ? <Unarchive /> : <Archive />}
                </IconButton>
              </Stack>
              <Stack margin="15px 0px" direction="row" justifyContent="space-between" alignItems="center">
                  <Typography component="span" color='rgba(27, 31, 38, 0.72)' fontSize="18px" fontWeight={400}>
                  Location: {note.locationType} {note.location}
                  </Typography>
                  <Typography component="span" color='rgba(27, 31, 38, 0.72)' fontSize="18px" fontWeight={400}>
                  {getFormatedDateAndTime(note.createdAt)}
                  </Typography>
              </Stack>
             {
              isDraftNote ?  (
                <StyledChip sx={{ width: '100%'}} label="Edit Draft" color='primary' variant='outlined' onClick={()=>{
                  setIsCreating(true);
                  setInitialState(note);
                }}/>
              ) : (
                <Stack direction="row" justifyContent="space-between">
                {
                  noteOptions.map((option) => <StyledChip disabled={note.isArchived || note.hasFollowUp} onClick={()=>{
                      setnoteAction({...notevalues[option], note})
                  }} color='primary' label={option} variant='outlined' />)
                }
              </Stack>
              )
             }
            </Box>
            )
        })
      }
      </Stack>
      </DialogContent>
    </> 
  )
}

export default MyNotesContent
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { ClerkProvider } from '@clerk/clerk-react';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
import IntercomMessenger from './components/IntercomMessenger';
import ErrorBoundary from './features/pages/ErrorBoundary';
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const frontendApi = process.env.REACT_APP_CLERK_SECRET_KEY;
const publishableKey = process.env.REACT_APP_PUBLIC_CLERK_PUBLISHABLE_KEY;


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV || "production",
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ClerkProvider
    afterSignOutUrl="/"
    frontendApi={frontendApi}
    publishableKey={publishableKey}
    appearance={{
      layout: {
        privacyPageUrl: "https://drh.ai/legal",
        helpPageUrl: "https://drh.ai/contact",
      },
      elements: {
        footerPages: {
          flexDirection: "row-reverse",
        },
        footer: {
          display: "flex",
          flexDirection: "column-reverse",
          gap: 6,
        },
        footerPagesLink__privacy: {
          color: "rgb(16, 63, 239)",
        },
      },
    }}
    >
      <IntercomMessenger />
      <ErrorBoundary>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider autoHideDuration={2000} TransitionComponent={(props) => <Slide {...props} direction="left" />}  anchorOrigin={{horizontal: 'right', vertical: 'top'}} maxSnack={3} >
          <App />
        </SnackbarProvider>
        </PersistGate>
      </Provider>
      </ErrorBoundary>
    </ClerkProvider>
  // </React.StrictMode>
);
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeTool } from "../../slices/clinicalToolSlice";
import { updateNoteDetails } from "../../slices/noteDetialsSlice";
import {
  Card,
  CardContent,
  Box,
  IconButton,
  Stack,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Button,
  TextField
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";

const yearsCriteria = [
  { id: 1, name: "Clinical signs of DVT?", options: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }] },
  { id: 2, name: "Hemoptysis (coughing blood)?", options: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }] },
  { id: 3, name: "PE is the most likely diagnosis?", options: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }] },
];

const YEARSAlgorithm = () => {
  const dispatch = useDispatch();

  const [pregnant, setPregnant] = useState(() => {
    const stored = localStorage.getItem("pregnantStatus");
    return stored !== null ? JSON.parse(stored) : null;
  });

  const [selections, setSelections] = useState(() => {
    const savedSelections = localStorage.getItem("yearsSelections");
    return savedSelections ? JSON.parse(savedSelections) : yearsCriteria.map(() => -1);
  });

  const [ultrasound, setUltrasound] = useState(() => {
    const storedUltrasound = localStorage.getItem("ultrasoundStatus");
    return storedUltrasound && storedUltrasound !== "undefined" ? storedUltrasound : "";
  });

  const [dDimer, setDDimer] = useState("");

  useEffect(() => {
    localStorage.setItem("yearsSelections", JSON.stringify(selections));
    localStorage.setItem("pregnantStatus", JSON.stringify(pregnant));
    localStorage.setItem("ultrasoundStatus", ultrasound);
  }, [selections, pregnant, ultrasound]);

  const totalScore = selections.reduce((acc, val) => acc + val, 0);
  const dDimerCutoff = totalScore >= 1 ? 500 : 1000;

  const getDiagnosis = () => {
    if (pregnant) {
      if (selections[0] === 1) {
        if (!ultrasound) return "Select Ultrasound Result";
        return ultrasound === "abnormal" ? "Initiate Anticoagulant Treatment" : "Continue with assessment";
      }
    }
    if (!dDimer || isNaN(dDimer) || parseFloat(dDimer) <= 0) return "Enter a valid D-Dimer value.";
    return parseFloat(dDimer) < dDimerCutoff ? "PE Excluded" : "Order CTPA";
  };

  const getContent = () => {
    return {
      heading: "YEARS Algorithm Result:",
      key: "years_algorithm",
      content: ultrasound === "abnormal" ?
        `Pregnant: ${pregnant ? "Yes" : "No"}\nUltrasound: ${ultrasound}\nDiagnosis: ${getDiagnosis()}` :
        `Pregnant: ${pregnant ? "Yes" : "No"}\nYEARS Score: ${totalScore}\nD-Dimer: ${dDimer || "N/A"} ng/mL\nD-Dimer Cutoff: ${dDimerCutoff} ng/mL\nUltrasound: ${ultrasound || "N/A"}\nDiagnosis: ${getDiagnosis()}\nInputs:\n${yearsCriteria
          .map((item, index) => ` ${item.name}: ${selections[index] === 1 ? "Yes" : "No"}`)
          .join("\n")}`,
    };
  };

  const isInsertDisabled = () => {
    if (pregnant) {
      if (selections[0] === 1) { 
        if (!ultrasound) return true;
        if (ultrasound === "normal") {
          return !dDimer || isNaN(dDimer) || parseFloat(dDimer) <= 0;
        }
        return false;
      }
    }
    return selections.includes(-1) || !dDimer || isNaN(dDimer) || parseFloat(dDimer) <= 0;
  };
  

  return (
    <Card variant="elevation" elevation={3} sx={{ width: "100%", marginBottom: "15px", backgroundColor: "#EDF2FF" }}>
      <CardContent>
        <Stack direction="row-reverse">
          <IconButton onClick={() => {
            localStorage.removeItem("selectedTool");
            localStorage.removeItem("yearsSelections");
            localStorage.removeItem("pregnantStatus");
            localStorage.removeItem("ultrasoundStatus");
            dispatch(closeTool());
          }} size="large" color="primary">
            <Close />
          </IconButton>
        </Stack>
        <Typography color="#202224" fontWeight={500} fontSize="24px">
          YEARS Algorithm for Pulmonary Embolism
        </Typography>

        {/* Pregnant Status */}
        <Box bgcolor="white" padding="10px" borderRadius="8px" marginBottom="20px">
          <Typography fontWeight={500} fontSize="18px">Is the patient pregnant?</Typography>
          <List sx={{ display: "flex", gap: "10px", bgcolor: "white", borderRadius: "8px" }}>
            <ListItemButton
              sx={{
                flex: 1,
                "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
                "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
              }}
              selected={pregnant === true} onClick={() => setPregnant(true)}><ListItemText primary="Yes" /></ListItemButton>
            <ListItemButton
              sx={{
                flex: 1,
                "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
                "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
              }}
              selected={pregnant === false} onClick={() => setPregnant(false)}><ListItemText primary="No" /></ListItemButton>
          </List>
        </Box>

        {yearsCriteria.map((item, index) => (
          (!pregnant || (pregnant && (index === 0 || selections[0] === 0))) && (
            <Box key={item.id} marginBottom="10px">
              <Typography fontWeight={500}>{item.name}</Typography>
              <List sx={{ bgcolor: "white", borderRadius: "8px" }}>
                {item.options.map((option) => (
                  <ListItemButton
                    key={option.label}
                    sx={{
                      flex: 1,
                      "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
                      "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
                    }}
                    selected={selections[index] === option.value}
                    onClick={() => {
                      const newSelections = [...selections];
                      newSelections[index] = option.value;
                      setSelections(newSelections);
                    }}
                  >
                    <ListItemText primary={option.label} />
                  </ListItemButton>
                ))}
              </List>
            </Box>
          )
        ))}

        {pregnant && selections[0] === 1 && (
          <Box bgcolor="white" padding="10px" borderRadius="8px" marginBottom="20px">
            <Typography fontWeight={500} fontSize="18px">Compression Ultrasonography Result</Typography>
            <ListItemButton
              sx={{
                flex: 1,
                "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
                "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
              }}
              selected={ultrasound === "normal"} onClick={() => setUltrasound("normal")}><ListItemText primary="Normal" /></ListItemButton>
            <ListItemButton
              sx={{
                flex: 1,
                "&.Mui-selected": { backgroundColor: "#2E6FF3", color: "white" },
                "&.Mui-selected:hover": { backgroundColor: "#2E6FF3", color: "white" },
              }}
              selected={ultrasound === "abnormal"} onClick={() => setUltrasound("abnormal")}><ListItemText primary="Abnormal" /></ListItemButton>
          </Box>
        )}

        {(!pregnant || (pregnant && ultrasound === "normal")) && (
          <TextField fullWidth label="D-Dimer Value (ng/mL)" type="number" value={dDimer} onChange={(e) => setDDimer(e.target.value)} sx={{ marginBottom: "20px" }} />
        )}

        <Typography fontSize="20px" fontWeight="600" color="primary">{getDiagnosis()}</Typography>

        <Button disabled={isInsertDisabled()}
          onClick={() => {
            localStorage.setItem('Dtool', true)
            dispatch(
              updateNoteDetails({ key: 'summary_json', value: getContent() })
            );
            localStorage.removeItem("selectedTool");
            localStorage.removeItem("yearsSelections");
            localStorage.removeItem("pregnantStatus");
            localStorage.removeItem("ultrasoundStatus");
            dispatch(closeTool());
          }}
          sx={{ bgcolor: "#2E6FF3" }} variant="contained" startIcon={<Add />}>Insert</Button>
      </CardContent>
    </Card>
  );
};

export default YEARSAlgorithm;

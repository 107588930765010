const metadata = {
    "EmergencyMedicine": {
      title: "Emergency Medicine Template",
      noteSection: {
        exampleNote: [
            `CHIEF COMPLAINT(S):
            [primary reason(s) for the patient's visit.]
            
            HISTORY OF PRESENT ILLNESS:
            [A structured summary of the patient's symptoms, past medical history, onset, location, duration, character, aggravating/relieving factors, timing, severity, and relevant positives/negatives.]
            
            ADDITIONAL HISTORY OBTAINED FROM SOURCES OTHER THAN THE PATIENT: 
            [Documentation of information provided by family, EMS, or other sources with proper attribution.]
            
            EXTERNAL RECORDS REVIEWED:
            [Relevant past visit records, labs, imaging, and external documentation you verbalized that you reviewed during the encounter.]
            
            CHRONIC MEDICAL CONDITIONS SIGNIFICANTLY AFFECTING CARE:
            [A list of chronic conditions impacting the patient’s current treatment or clinical decision-making.]
            
            SOCIAL DETERMINANTS AFFECTING HEALTH:
            [Factors such as financial hardship, housing instability, substance use, or caregiver stress that influence the patient's health.]
            
            ALLERGIES:
            [Documentation of any reported drug, environmental, or food allergies if mentioned.]
            
            PAST MEDICAL HISTORY:
            [A list of significant past medical conditions relevant to the patient's health.]
            
            PAST SURGICAL HISTORY:
            [A record of prior surgical procedures if applicable.]
            
            FAMILY HISTORY:
            [Relevant medical history of immediate family members if it impacts the patient's health.]
            
            IMMUNIZATION HISTORY:
            [A summary of the patient’s vaccination history if discussed.]
            
            SOCIAL HISTORY:
            [Details on smoking, alcohol, or drug use, specifying the substance and cessation status if applicable.]
            
            MEDICATIONS:
            [A list of current medications and dosages if mentioned during the conversation.]
            
            REVIEW OF SYSTEMS:
            [A bullet-pointed review of symptoms by system, avoiding abbreviations for clarity.]
            
            PHYSICAL EXAM:
            [Objective findings from the examination, organized by the body system.]
            
            PROBLEM LIST:
            [Categorization of the patient's acute and chronic medical problems if discussed.]
            
            PLAN:
            [The treatment and management plan as discussed during the conversation.]
            
            DIFFERENTIAL DIAGNOSIS:
            [A bullet-pointed list of 10 potential diagnoses.]
            
            DISPOSITION NOTE
            DIAGNOSIS:
            [A suggested diagnosis or multiple diagnoses based on the information provided, with corresponding ICD-10 codes.]
            
            SUMMARY OF ENCOUNTER:
            [A detailed narrative summarizing why the patient was seen in the emergency department, the management decisions made, and the reasoning behind admission or discharge, based on your medical judgment and what was best for the patient.]
            
            DISPOSITION:
            [The final outcome of the visit, such as discharge, admission, transfer, left against medical advice (AMA), or eloped—only included if explicitly mentioned.]
            
            CONSIDERATION FOR ADMISSION:
            [Whether admission was considered for the patient—only included if explicitly mentioned.]
            
            ASSESSMENT:
            [A summary of what may be going on with the patient, based on the encounter details and medical judgment.]
            
            EMERGENCY TREATMENTS ADMINISTERED:
            [Medications or treatments provided in the emergency department—only included if explicitly mentioned.]
            
            MANAGEMENT OF THE PATIENT'S CARE WAS DISCUSSED WITH:
            [Details of any consultations with specialists, hospitalists, surgeons, or other healthcare providers—only included if explicitly mentioned.]
            
            REASSESSMENT:
            [Findings from any reassessment conducted during the encounter—only included if explicitly mentioned.]
            
            PLAN:
            [The management plan for the patient, including treatment recommendations and follow-up—only included if explicitly mentioned.]
            
            INDEPENDENT INTERPRETATION OF TESTS:
            [Includes a review of labs and radiology studies.]
            
            ADDITIONAL TESTING AND IMAGING CONSIDERED:
            [Any labs or imaging that were considered but not ordered, along with the reasoning—only included if specifically mentioned.]
            
            PROCEDURES:
            [A structured, billable description of any procedures performed—only included if explicitly mentioned.]
            
            PATIENT EDUCATION AND COUNSELING:
            [Information provided to the patient about their diagnosis, treatment, or overall health—only included if discussed.]
            
            FOLLOW-UP INSTRUCTIONS:
            [Plans for follow-up with specialists or primary care, including specific timeframes—only included if explicitly mentioned.]
            
            MEDICATION RECONCILIATION:
            [A record of all medications administered during the visit or prescribed upon discharge, ensuring all prescriptions and dosages are documented.]
            
            SMOKING CESSATION COUNSELING:
            [Documentation of counseling provided to current smokers about the health risks of tobacco, nicotine, and cannabis use, with encouragement to quit and cessation options discussed.]
            
            MEDICAL DECISION MAKING:
            Number and Complexity of Problems Addressed: [chronic conditions affecting care and differential diagnoses considered.]
            Data Reviewed: [Includes labs, imaging, external records, and information obtained from independent historians.]
            Risk Assessment: [Documents decisions regarding prescription medications, high-risk testing, procedures, or escalation of care.]
            CRITICAL CARE TIME: [Critical care time due to a high probability of life-threatening deterioration, requiring my highest level of preparedness to intervene emergently."—only included if critical care time was explicitly mentioned.]
            CARE UPDATE: [Concise additions capturing new findings, status changes, or treatment modifications from a reassessment, consultant discussion, or other updates. Only includes explicitly mentioned details.]
            
            EKG: 
            [Time, Rhythm, Rate, PR interval, QRS duration, QT interval, Axis, and abnormalities.]

            CARE UPDATE: 
            [Concise additions capturing new findings, status changes, or treatment modifications from a reassessment, consultant discussion, or other updates. Only includes explicitly mentioned details.]

            DIAGNOSIS:
            [A suggested diagnosis or multiple diagnoses based on the information provided, with corresponding ICD-10 codes.]
            
            SUMMARY OF ENCOUNTER:
            [A detailed narrative summarizing why the patient was seen in the emergency department, the management decisions made, and the reasoning behind admission or discharge, based on your medical judgment and what was best for the patient.]
            
            DISPOSITION:
            [The final outcome of the visit, such as discharge, admission, transfer, left against medical advice (AMA), or eloped—only included if explicitly mentioned.]
            CONSIDERATION FOR ADMISSION:
            [Whether admission was considered for the patient—only included if explicitly mentioned.]
            
            ASSESSMENT:
            [A summary of what may be going on with the patient, based on the encounter details and medical judgment.]
            
            EMERGENCY TREATMENTS ADMINISTERED:
            [Medications or treatments provided in the emergency department—only included if explicitly mentioned.]
            
            MANAGEMENT OF THE PATIENT'S CARE WAS DISCUSSED WITH:
            [Details of any consultations with specialists, hospitalists, surgeons, or other healthcare providers—only included if explicitly mentioned.]
            
            REASSESSMENT:
            [Findings from any reassessment conducted during the encounter—only included if explicitly mentioned.]
            
            PLAN:
            [The management plan for the patient, including treatment recommendations and follow-up—only included if explicitly mentioned.]
            
            INDEPENDENT INTERPRETATION OF TESTS:
            [Includes a review of labs and radiology studies.]
            
            ADDITIONAL TESTING AND IMAGING CONSIDERED:
            [Any labs or imaging that were considered but not ordered, along with the reasoning—only included if specifically mentioned.]
            
            PROCEDURES:
            [A structured, billable description of any procedures performed—only included if explicitly mentioned.]
            
            PATIENT EDUCATION AND COUNSELING:
            [Information provided to the patient about their diagnosis, treatment, or overall health—only included if discussed.]
            
            FOLLOW-UP INSTRUCTIONS:
            [Plans for follow-up with specialists or primary care, including specific timeframes—only included if explicitly mentioned.]
            
            MEDICATION RECONCILIATION:
            [A record of all medications administered during the visit or prescribed upon discharge, ensuring all prescriptions and dosages are documented.]
            
            SMOKING CESSATION COUNSELING:
            [Documentation of counseling provided to current smokers about the health risks of tobacco, nicotine, and cannabis use, with encouragement to quit and cessation options discussed.]
            
            MEDICAL DECISION MAKING:
            Number and Complexity of Problems Addressed: [chronic conditions affecting care and differential diagnoses considered.]
            Data Reviewed: [Includes labs, imaging, external records, and information obtained from independent historians.]
            Risk Assessment: [Documents decisions regarding prescription medications, high-risk testing, procedures, or escalation of care.]
            
            CRITICAL CARE TIME: 
            [Critical care time due to a high probability of life-threatening deterioration, requiring my highest level of preparedness to intervene emergently."—only included if critical care time was explicitly mentioned.]
          `
        ],
      },
    },
    "FastTrack": {
      title: "Fast Track Note",
      noteSection: {
        exampleNote: [`CHIEF COMPLAINT(S):
          [Primary reason(s) for the patient's visit, including main symptoms and duration.]

          HISTORY OF PRESENT ILLNESS:
          [Structured summary of symptoms, including onset, location, duration, severity, aggravating/relieving factors, and relevant positives/negatives.]

          ADDITIONAL HISTORY OBTAINED FROM SOURCES OTHER THAN THE PATIENT:
          [Information provided by family, EMS, caregivers, or other sources with proper attribution.]

          EXTERNAL RECORDS REVIEWED:
          [Prior visit notes, labs, imaging, and external medical documents reviewed during the encounter.]

          CHRONIC MEDICAL CONDITIONS SIGNIFICANTLY AFFECTING CARE:
          [List of chronic illnesses impacting current treatment or clinical decision-making.]

          SOCIAL DETERMINANTS AFFECTING HEALTH:
          [Factors such as financial hardship, housing instability, substance use, or caregiver stress affecting health.]

          ALLERGIES:
          [Documented drug, food, or environmental allergies, including reaction type and severity.]

          PAST MEDICAL HISTORY:
          [Significant past medical conditions relevant to the patient’s current health.]

          PAST SURGICAL HISTORY:
          [Record of previous surgical procedures, including type and date if available.]

          FAMILY HISTORY:
          [Relevant health conditions in immediate family members impacting the patient’s health.]

          IMMUNIZATION HISTORY:
          [Summary of vaccination history, including recent or required immunizations.]

          SOCIAL HISTORY:
          [Smoking, alcohol, or drug use, including type, frequency, and cessation status if applicable.]

          MEDICATIONS:
          [Current medications, including dosage, frequency, and route of administration.]

          REVIEW OF SYSTEMS:
          [Bullet-pointed review of symptoms organized by body system.]

          PHYSICAL EXAM:
          [Objective findings from examination, categorized by body system.]

          PROBLEM LIST:
          [Categorized list of acute and chronic medical conditions discussed.]

          PLAN:
          [Outline of treatment and management, including diagnostics, medications, procedures, follow-up, and lifestyle modifications.]

          DIFFERENTIAL DIAGNOSIS:
          [Bullet-pointed list of 10 possible diagnoses based on symptoms and clinical findings.]
          `],
    }},
    "Hospitalist": {
      title: "Hospitalist Template",
      noteSection: {
        exampleNote: [
            `SERVICE DATE AND TIME:
            [The date and time of the patient encounter.]
            
            CODE STATUS:
            [The patient’s resuscitation preferences (e.g., Full Code, DNR, DNI), if documented.]
            
            PRIMARY CARE PROVIDER:
            [The name of the patient’s primary care provider, if mentioned.]
            
            CHIEF COMPLAINT(S):
            [The main reason for the patient’s visit, as verbalized by the patient.]
            
            HISTORY OF PRESENT ILLNESS:
            [A structured summary of the patient’s symptoms, including onset, location, duration, character, aggravating/relieving factors, timing, severity, and pertinent positives/negatives, as verbalized by the patient.]
            
            PAST MEDICAL HISTORY:
            [A list of chronic medical conditions relevant to the patient’s current care, as verbalized by the patient.]
            
            PAST SURGICAL HISTORY:
            [A record of prior surgical procedures, as verbalized by the patient.]
            
            FAMILY HISTORY:
            [Relevant medical history of immediate family members, if it impacts the patient’s health, as verbalized by the patient.]
            
            SOCIAL HISTORY:
            [Details on smoking, alcohol, or drug use, specifying the substance and cessation status if applicable, as verbalized by the patient.]
            
            MEDICATIONS PRIOR TO ADMISSION:
            [A list of home medications the patient was taking before admission, including dosages if mentioned.]
            
            ALLERGIES:
            [Any reported drug, environmental, or food allergies, if mentioned, as verbalized by the patient.]
            
            REVIEW OF SYSTEMS:
            [A bullet-pointed review of symptoms by system, avoiding abbreviations for clarity, as verbalized by the patient.]
            
            VITALS:
            [The patient’s vital signs, if documented or explicitly mentioned.]
            
            PHYSICAL EXAM:
            [Objective exam findings by system, avoiding abbreviations for clarity, and ensuring documentation of nursing notes and vital sign review.]
            
            LINES/DRAINS/AIRWAY:
            [Documentation of any central lines, drains, or airway devices in place, if applicable.]
            
            LABS:
            [A list of lab results reviewed, ensuring independent interpretation of any mentioned lab abnormalities.]
            
            IMAGING:
            [A summary of imaging studies reviewed, formatted as "My independent interpretation of [imaging type] indicates [findings]."]
            
            ASSESSMENT AND PLAN:
            [A summary of clinical reasoning, diagnoses, and management strategy for the patient.]
            
            DVT PROPHYLAXIS:
            [Documentation of whether deep vein thrombosis prophylaxis is in place, if applicable.]
            
            PRESSURE ULCER:
            [Assessment and documentation of any pressure ulcers, if mentioned.]
            
            OBESITY:
            [Evaluation of the patient’s obesity status and its impact on care, if relevant.]
            
            NUTRITION STATUS:
            [Assessment of the patient’s nutritional status, including dietary considerations or interventions, if discussed.]
            
            DISPOSITION:
            [The final outcome of the visit (e.g., discharge, admission, transfer, AMA, elopement), if explicitly mentioned.]
            
            PLAN OF CARE DISCUSSED WITH:
            [Details of discussions with the patient, family, or consultants regarding the treatment plan, if explicitly mentioned.]
            
            BILL BY TIME:
            [Documentation of total time spent on patient care, if applicable.]`
      ],
    }},
    "HospitalistHP": {
      title: "Hospitalist H&P Note",
      noteSection: {
        exampleNote: [
            `HISTORY OF PRESENT ILLNESS:
            [List and describe each issue, problem, or request with relevant patient discussion.]
            
            Review of systems:
            [Review relevant body systems and note any positive or negative findings.]
            
            Physical exam:
            [Document detailed physical examination findings by body system or region.]
            
            ASSESSMENT AND PLAN:
            [For each issue, provide assessment, likely diagnosis, differential diagnoses, investigations, treatment plans, and any relevant referrals.]
            
            CHIEF COMPLAINT:
            [State the main reason or question prompting the consult.]
            
            HISTORY OF PRESENT ILLNESS:
            [Include patient age, relevant past medical history, and a detailed narrative of the current issue, including onset, severity, and any contributing factors.]
            
            PAST MEDICAL HISTORY:
            [List relevant past medical conditions if applicable.]
            
            HOME MEDICATIONS:
            [Include current medications with dosages if applicable.]
            
            ALLERGIES:
            [List any known allergies if applicable.]
            
            SOCIAL HISTORY:
            [Provide relevant details on tobacco, alcohol, drug use, and living situation if applicable.]
            
            FAMILY HISTORY:
            [Include relevant family medical history if applicable.]
            
            REVIEW OF SYSTEM:
            [Summarize symptoms related to relevant body systems, only including those applicable to the current illness.]
            
            PHYSICAL EXAM:
            [Record vital signs and findings from physical exams, only including relevant observations.]
            
            LAB RESULTS:
            [Include relevant lab results such as blood tests or cultures, if applicable.]
            
            IMAGING RESULTS:
            [Document results from any relevant imaging studies like X-rays, CT scans, or MRIs, if applicable.]
            
            ASSESSMENT AND PLAN:
            [For each issue, provide an assessment, differential diagnosis, planned investigations, treatments, and any necessary referrals.]
            
            TWO MIDNIGHT DOCUMENTATION:
            [State the anticipated length of hospital stay due to the severity of the illness.]
            
            TIME SPENT:
            [Indicate the total time spent in care or assessment.]`
      ],
    }},
    "HospitalistDischarge": {
      title: "Hospitalist Discharge Note",
      noteSection: {
        exampleNote: [
            `DISCHARGE:
            [Summarize the patient's clinical course, including treatment methods, significant events, changes in condition, and any interventions, if mentioned.]`
      ],
    }},
    "HospitalistProgress": {
      title: "Hospitalist Progress Note",
      noteSection: {
        exampleNote: [
            `CLINICAL COURSE:
            [Summarize the patient's admission details, diagnosis, presenting chief complaint, and clinical course, including significant events, changes, or interventions (if mentioned).]
            
            Today's Updates (Insert date):
            [Describe the patient's condition, any significant events or interventions in the last 24 hours, and relevant test or imaging results (if mentioned).]
            
            REVIEW OF SYSTEM:
            [List relevant positive or negative findings from the review of systems (if mentioned).]
            
            PHYSICAL EXAM:
            [Describe the findings from the physical examination, including vital signs and system-specific examinations (if available). If not available, use default format.]
            
            ASSESSMENT & PLAN:
            [Provide a summary of the patient's clinical course and plan for each medical issue, including assessments, management plans, and counseling (if discussed).]
            [Include fluid, electrolyte, diet details, DVT prophylaxis, central line, Foley catheter status, code status, and disposition if mentioned.]`
      ],
    }},
    "SOAP": {
      title: "Primary Care SOAP Note",
      noteSection: {
        exampleNote: [
            `SUBJECTIVE:
              [Describe current issues, reasons for visit, discussion topics, history of presenting complaints, etc.]
              [Describe past medical history, previous surgeries.]
              [Mention medications and herbal supplements.]
              [Describe social history.]
              [Mention allergies.]
              [Description of symptoms, onset of symptoms, location of symptoms, duration of symptoms, characteristics of symptoms, alleviating or aggravating factors, timing, and severity.]
              [Current medications and response to treatment.]
              [Any side effects experienced.]
              [Non-pharmacological interventions tried.]
              [Description of any related lifestyle factors.]
              [Patient's experience and management of symptoms.]
              [Any recent changes in symptoms or condition.]
              [Any pertinent positive or pertinent negatives in review of systems.]
              
              REVIEW OF SYSTEMS:
              - General: [weight loss, fever, fatigue, etc.]
              - Skin: [rashes, itching, dryness, etc.]
              - Head: [headaches, dizziness, etc.]
              - Eyes: [vision changes, pain, redness, etc.]
              - Ears: [hearing loss, ringing, pain, etc.]
              - Nose: [congestion, nosebleeds, etc.]
              - Throat: [sore throat, hoarseness, etc.]
              - Neck: [lumps, pain, stiffness, etc.]
              - Respiratory: [cough, shortness of breath, wheezing, etc.]
              - Cardiovascular: [chest pain, palpitations, etc.]
              - Gastrointestinal: [nausea, vomiting, diarrhea, constipation, etc.]
              - Genitourinary: [frequency, urgency, pain, etc.]
              - Musculoskeletal: [joint pain, muscle pain, stiffness, etc.]
              - Neurological: [numbness, tingling, weakness, etc.]
              - Psychiatric: [depression, anxiety, mood changes, etc.]
              - Endocrine: [heat/cold intolerance, excessive thirst, etc.]
              - Hematologic/Lymphatic: [easy bruising, swollen glands, etc.]
              - Allergic/Immunologic: [allergies, frequent infections, etc.]
              
              OBJECTIVE:
              Vital Signs:
              - Blood Pressure: [reading]
              - Heart Rate: [reading]
              - Respiratory Rate: [reading]
              - Temperature: [reading]
              - Oxygen Saturation: [reading]
              - General Appearance: [description]
              - Head, Ears, Eyes, Nose, Throat: [findings]
              - Neck: [findings]
              - Cardiovascular: [findings]
              - Respiratory: [findings]
              - Abdomen: [findings]
              - Musculoskeletal: [findings]
              - Neurological: [findings]
              - Skin: [findings]
              
              ASSESSMENT:
              Issue 1: [Condition name]
              - Assessment: [Likely diagnosis]
              - Differential Diagnosis: [If explicitly mentioned]
              - Diagnostic Tests: [Planned investigations]
              - Treatment Plan:
              - [Treatment details]
              - [Relevant referrals]
              
              Issue 2: [Condition name]
              - Assessment: [Likely diagnosis]
              - Differential Diagnosis: [If explicitly mentioned]
              - Diagnostic Tests: [Planned investigations]
              - Treatment Plan:
              - [Treatment details]
              - [Relevant referrals]
              - Additional Issues (if any):
              - Assessment: [Likely diagnosis]
              - Differential Diagnosis: [If explicitly mentioned]
              - Diagnostic Tests: [Planned investigations]
              - Treatment Plan:
              - [Treatment details]
              - [Relevant referrals]
              
              FOLLOW-UP:
              - [Instructions for when to seek emergent follow-up care.]
              - [Monitoring and follow-up recommendations.]`
      ],
    }},
    "PCProblemBased": {
      title: "Primary Care Problem Based Note",
      noteSection: {
        exampleNote: [
            `HISTORY OF PRESENT ILLNESS:
            [List and describe each issue, problem, or request with relevant patient discussion.]
            
            Review of systems:
            [Review relevant body systems and note any positive or negative findings.]
            
            Physical exam:
            [Document detailed physical examination findings by body system or region.]
            
            ASSESSMENT AND PLAN:
            [For each issue, provide assessment, likely diagnosis, differential diagnoses, investigations, treatment plans, and any relevant referrals.]`
      ],
    }},
    "PsychiatryIntake": {
      title: "Psychiatry Intake Note",
      noteSection: {
        exampleNote: [
            `REASON FOR VISIT:
            [Describe the primary reason or concern prompting the visit, if mentioned.]

            HISTORY OF PRESENT ILLNESS:
            [Include details on the onset, duration, and progression of psychiatric symptoms, if provided.]

            PAST PSYCHIATRIC HISTORY:
            [Mention any past psychiatric conditions, hospitalizations, treatments, or diagnoses, if applicable.]

            PAST MEDICAL HISTORY:
            [Include relevant medical conditions that could impact psychiatric health, if applicable.]

            MEDICATIONS:
            [List current medications, including psychiatric medications and dosages, if specified.]

            ALLERGIES:
            [List any known allergies, if mentioned.]

            MEDICATION TRIALS:
            [Describe previous psychiatric medication trials, their efficacy, and side effects, if applicable.]

            ACCESS TO WEAPONS:
            [Mention any access to weapons or firearms, if noted.]

            SOCIAL HISTORY:
            [Provide details on living situation, relationships, and support systems, if applicable.]

            EDUCATION:
            [Describe education history and current educational level, if mentioned.]

            RELATIONSHIPS:
            [Include information on interpersonal relationships, family dynamics, or intimate relationships, if applicable.]

            WORK:
            [Mention work history, current employment status, or work-related stressors, if noted.]

            SUPPORTS:
            [Describe social, community, or therapeutic supports, if mentioned.]

            PROTECTIVE FACTORS:
            [Include protective factors such as coping strategies and support systems, if mentioned.]

            NEGATIVE FACTORS:
            [List negative factors or stressors like substance use or ongoing trauma, if applicable.]

            SUICIDE RISK ASSESSMENT:
            [Summarize suicide risk assessment, including ideation, intent, plan, and protective factors, if mentioned.]

            FORENSIC HISTORY:
            [Include legal history, arrests, charges, or any legal matters, if applicable.]

            FAMILY HISTORY:
            [Mention family history of psychiatric or medical conditions, if relevant.]

            MENTAL STATUS EXAM:
            [Describe findings from the mental status exam, including appearance, behavior, mood, thought processes, and cognition, if provided.]

            OBJECTIVE FINDINGS:
            [Include objective findings like physical exam or lab results, if applicable.]

            DIAGNOSIS:
            [Mention the diagnosis based on DSM-5 criteria, if provided.]

            DIAGNOSIS FORMULATION:
            [Summarize the diagnostic formulation, considering biopsychosocial factors, if specified.]

            ASSESSMENT AND PLAN:
            [Outline the assessment and plan, including treatment goals, medications, and follow-up recommendations, if provided.]`
      ],
    }},
    "PsychiatryDetailed": {
      title: "Psychiatry Detailed Note",
      noteSection: {
        exampleNote: [
            `CHIEF COMPLAINT(S):
            [Patient's main complaint in their own words.]
            
            HISTORY OF PRESENT ILLNESS:
            [Brief summary of the illness' onset, duration, and severity.]
            
            PSYCHIATRIC REVIEW OF SYSTEM:
            Depressive symptoms: [Description of depressive symptoms.]
            Anxiety symptoms: [Description of anxiety symptoms.]
            Sleep: [Patient’s sleep patterns and related issues.]
            Appetite: [Patient’s appetite status.]
            Suicidal and homicidal ideations: [Any suicidal or homicidal thoughts or plans.]
            Auditory and visual hallucinations: [Report of auditory or visual hallucinations.]
            Delusions/paranoia: [Any delusional or paranoid thinking.]
            Manic symptoms: [Patient’s report of manic behaviors.]
            
            PAST PSYCHIATRIC HISTORY:
            Prior diagnosis: [Patient’s psychiatric diagnoses.]
            Hospitalizations in psychiatric units: [History of psychiatric hospitalizations.]
            Previous suicide attempts: [Any history of suicide attempts.]
            History of self harm: [Any self-harm history.]
            Access to firearms: [Patient’s access to firearms.]
            Psychotropic medications: [Current or past use of psychotropic medications.]
            Current psychiatrist and therapist: [Patient’s current mental health care providers.]
            Cures report: [Availability of patient's CURES report.]
            FAMILY HISTORY OF PSYCHIATRIC/SUBSTANCE USE HISTORY:
            [Family history of psychiatric or substance use disorders.]
            
            SUBSTANCE USE HISTORY:
            Alcohol: [History of alcohol use and patterns.]
            Cannabis: [History of cannabis use.]
            Amphetamines: [History of amphetamine use.]
            Nicotine: [History of nicotine use.]
            Other substances: [Use of any other substances.]
            
            MEDICAL HISTORY:
            [Patient’s medical history.]
            MEDICAL REVIEW OF SYSTEMS:
            [Results of the medical review of systems.]
            
            CURRENT MEDICATIONS:
            [Patient's current medications.]
            
            ALLERGIES:
            [Known allergies.]
            
            SOCIAL HISTORY:
            Marital Status: [Patient's marital status.]
            Children: [Number and ages of children, if applicable.]
            Living situation: [Patient's living arrangements.]
            Employment: [Patient's employment status and details.]
            Education: [Patient's education level.]
            Support System: [Family and friends who provide support.]
            
            OBJECTIVE:
            Mental Status Evaluation:
            Appearance: [Patient’s general appearance.]
            Cognition: [Patient's cognitive functioning.]
            Speech: [Speech patterns.]
            Mood: [Patient's reported mood.]
            Affect: [Patient’s emotional expression.]
            TP: [Assessment of thought process.]
            TC: [Assessment of thought content, including any ideations or delusions.]
            Perc: [Perceptual disturbances, such as hallucinations.]
            Insight/Judgment: [Patient's insight and judgment.]
            
            ASSESSMENT:
            [Summary of presentation, symptoms, and diagnostic impressions.]
            
            PLAN:
            Risk Assessment: [Risk of harm to self or others, and safety planning.]
            Status: [Treatment status (voluntary/involuntary).]
            Diagnostics: [Any necessary tests or referrals.]
            Treatment: [Approach and modalities for treatment.]
            Bio: [Biological interventions, including medications.]
            Psychosocial: [Psychosocial interventions and therapy options.]
            Patient's Participation in Treatment Plan: [Patient’s understanding and willingness.]
            
            Therapeutic Interventions:
            [Type of therapy and duration of session.]
            Symptoms or Challenges Discussed:
            [Specific symptoms or challenges addressed in the session.]
            Impact on the Patient's Functioning:
            [How symptoms affect the patient's daily life.]
            
            Specific Topics Covered:
            [Topics discussed during the session.]
            
            Client's Response:
            [Patient’s response to therapeutic interventions.]
            
            Prognosis:
            [Overall prognosis and risk for deterioration.]
            
            DIAGNOSIS:
            [Patient’s psychiatric diagnoses, with ICD-10 codes.]
            
            BILLING CODE:
            [Applicable billing codes for the services provided.]`
      ],
    }},
    "MtepEmergencyMedicine": {
      title: "MTEP Emergency Medicine Note",
      noteSection: {
        exampleNote: [``],
    }},
    "MtepTeachingNote": {
      title: "MTEP Teaching Note",
      noteSection: {
        exampleNote: [``],
    }},
    "MtepFastTrack": {
      title: "MTEP Fast Track Note",
      noteSection: {
        exampleNote: [``],
    }},
    "Counselor": {
      title: "Counselor Note",
      noteSection: {
        exampleNote: [
            `Current Presentation:
            [List key presenting issues or concerns]
            [Describe severity and impact of issues]
            
            Session Content:
            [Provide detailed summary of topics discussed during the session]
            [Include patient's thoughts, feelings, and insights shared]
            [Note any significant realizations or breakthroughs]
            
            Obstacles, Setbacks and Progress:
            [List main obstacles or challenges faced by the patient]
            [Describe any setbacks experienced since last session]
            [Highlight areas of progress or improvement]
            
            Session Summary:
            [Provide a comprehensive overview of the session]
            [Summarize key themes, insights, and developments]
            [Include therapist's observations and interpretations]
            
            Next Steps:
            [List any planned actions or goals for the patient]
            [Include date and time of next scheduled session]`
      ],
    }},
    "Dentist": {
      title: "Dentist Note",
      noteSection: {
        exampleNote: [
            `PATIENT INFORMATION:
            [Provide any relevant patient demographic details such as name, age, gender, contact information if available.]
            
            INCIDENT DETAILS:
            Date and Time of Trauma: [Mention the date and time of the traumatic incident.]
            Cause of Trauma: [Describe how the trauma occurred, e.g., fall, accident.]
            Location of Incident: [Mention where the trauma occurred, e.g., home, school.]
            Witnesses: [Mention if there were any witnesses and their accounts.]
            
            MEDICAL HISTORY:
            [Provide details of the patient’s medical history, conditions, surgeries, hospitalizations, allergies, and current medications.]
            
            CLINICAL EXAMINATION:
            
            EXTRA ORAL EXAMINATION:
            Facial Inspection: [Describe facial swelling, bruising, lacerations, or asymmetry.]
            TMJ Examination: [Mention any TMJ pain, issues, or clicking.]
            Lymph Node Examination: [Mention any lymph node enlargement or tenderness.]
            INTRA ORAL EXAMINATION
            
            Soft Tissue Assessment:
            Mucosa: [Describe the condition of the mucosa, including lacerations or bruising.]
            Gingiva: [Assess gingival health and signs of trauma or bleeding.]
            
            HARD TISSUE ASSESSMENT:
            Teeth: [Note the number, condition, and any fractures, luxations, or avulsions.]
            [Describe the alignment, mobility, and tenderness of affected teeth.]
            
            OCCLUSAL ANALYSIS:
            Molar Relationship: [Describe molar relationship and changes from trauma.]
            Canine Relationship: [Describe canine relationship and changes from trauma.]
            Midline Assessment: [Note any midline deviations.]
            
            RADIOGRAPHIC EXAMINATION:
            Periapical Radiographs: [Describe findings from periapical radiographs.]
            Panoramic Radiograph: [Describe findings from panoramic radiographs.]
            
            DIAGNOSTIC TESTS:
            Pulp Vitality Tests: [Mention pulp vitality test results for affected teeth.]
            Percussion and Palpation Tests: [Describe tenderness or abnormal responses.]
            
            EMERGENCY TREATMENT PROVIDED:
            Consent: [State whether written or verbal consent was obtained.]
            Discussed Relevant Risks: [Mention risks discussed, e.g., tooth fracture, pain, infection.]
            Local Anesthetic: [Mention the type and dosage of anesthetic used.]
            
            SOFT TISSUE MANAGEMENT:
            [Describe any treatment for soft tissue injuries, including suturing if applicable.]
            
            TOOTH STABILIZATION:
            Repositioning: [Mention if any displaced teeth were repositioned.]
            Splinting: [Describe the splint used and duration.]
            
            TEMPORARY RESTORATION:
            [Note if any temporary restorations were placed, including materials used.]
            
            POST-OPERATIVE INSTRUCTIONS:
            Post-Op Advice: [Discuss advice given on post-op care and recovery.]
            Post-Op Analgesics: [List any prescribed post-op analgesics.]
            Antibiotics: [List any prescribed antibiotics, including dosage and duration.]
            Hygiene Instructions: [Provide hygiene care instructions.]
            Dietary Advice: [Mention any dietary restrictions or advice.]
            Patient Questions: [Mention if patient had questions and if they were addressed.]
            
            FOLLOW-UP PLAN:
            Immediate Follow-Up: [State immediate follow-up plans for evaluation.]
            Long-Term Follow-Up: [Mention long-term follow-up plans, including assessments and treatments.]
            
            PROGNOSIS:
            [Describe the overall prognosis and potential for future treatments.]
            
            REVIEW AND FOLLOW-UP:
            [State follow-up dates and any planned evaluations.]`
      ],
    }},
};


export default metadata;